import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StockInComponent } from './stock-in/stock-in.component';
import { StockOutComponent } from './stock-out/stock-out.component';
import { LayoutComponent } from './layout/layout.component';
import { AuditComponent } from './audit/audit.component';
import { AuthGuard } from './AuthGuard';
import { StockComponent } from './stock/stock.component';
import { UserComponent } from './user/user.component';
import { IpwhitelistComponent } from './ipwhitelist/ipwhitelist.component';
import { LevelNRackComponent } from './level-nrack/levelnrack.component';

export const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: '', component: DashboardComponent, canActivate: [AuthGuard] }
      ],
     
    },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'checkin', component: StockInComponent , canActivate: [AuthGuard]}
      ] 
    },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'checkout', component: StockOutComponent, canActivate: [AuthGuard] }
      ] 
    },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'audit', component: AuditComponent , canActivate: [AuthGuard]}
      ] 
    },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'stock', component: StockComponent , canActivate: [AuthGuard]}
      ] 
    }, { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'user', component: UserComponent , canActivate: [AuthGuard]}
      ] 
    },
    { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'racknlevel', component: LevelNRackComponent , canActivate: [AuthGuard]}
      ] 
    }
    , { 
      path: '', 
      component: LayoutComponent, 
      children: [
        { path: 'ip', component: IpwhitelistComponent , canActivate: [AuthGuard]}
      ] 
    }
   
  ];

