import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { StockService } from '../service/stock.service';
import { SearchPipe } from '../pipe/SearchPipe';

@Component({
  selector: 'app-levelnrack',
  standalone: true,
  imports: [AgGridAngular,FormsModule],
  templateUrl: './levelnrack.component.html',
  styleUrl: './levelnrack.component.css'
})
export class LevelNRackComponent {
  detailGridApi: any;
  rowDetailFilteredData:any[]=[];
  rowDetailData:any[]=[];
  searchDetailText=""
  constructor(private stockService: StockService) { }
  detailColumnDefs = [
    { headerName: 'Product Name', field: 'productName', editable: false, width: 300 },
    { headerName: 'Grade', field: 'grade', editable: false },
    { headerName: 'Rack', field: 'rack', editable: false },
    { headerName: 'Level', field: 'level', editable: false },
    { headerName: 'Stock Type', field: 'stockType', editable: false },
    {
      headerName: 'Total Box', field: 'totalBox', width: 150
    },
    {
      headerName: 'Quantity Per Box', field: 'quantityPerBox', editable: false, width: 150,

    },
    {
      headerName: 'Quantity Loose', field: 'quantityLoss', width: 150
    },
    {
      headerName: 'Sold Quantity', field: 'soldQuantity', width: 150,
      cellStyle: (params: any) => {
        if (params.value < 0) {
          return { color: 'red' };
        }
        return null;
      }
    },
    { headerName: 'Net Quantity', field: 'netQuantity', editable: false },

  ];

  gridOptionsDetail = {
    columnDefs: this.detailColumnDefs,
    rowData: this.rowDetailFilteredData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.detailGridApi = params.api;
      this.getLevelNRack();
    }
  }
  handleDataDetailChange(){
    const searchPipe = new SearchPipe();
    this.rowDetailFilteredData = searchPipe.transform(this.rowDetailData, this.searchDetailText, ['productName', 'soNumber', 'stockType', 'level', 'rack']);
  }
  getLevelNRack(){
    this.detailGridApi.showLoadingOverlay();
    this.stockService.getLevelnRack().subscribe((resp)=>{
      this.rowDetailFilteredData = resp;
      this.rowDetailData = resp;
      this.detailGridApi.showLoadingOverlay();
    })
  }

}
