import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { connectingString } from '../contants';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private http: HttpClient) { }

  saveStock(object:any,type:string,message:string,isFutureStock:boolean = false): Observable<any> {
    return this.http.post(`${connectingString}Stock/AddStock?stockType=${type}&message=${message}&isFutureStock=${isFutureStock}`,object);
  }
  getStock(showFutureStock:boolean): Observable<any> {
    return this.http.get(`${connectingString}Stock/AllStocks?showFutureStock=${showFutureStock}`);
  }
  updateStock(object:any): Observable<any> {
    return this.http.put(`${connectingString}Stock/UpdateStock`,object);
  }
  deleteStock(id:any): Observable<any> {
    return this.http.post(`${connectingString}Stock/DeleteStock`,id);
  }
  getProductByStock(showActiveProduct:boolean,isFuture:boolean,showMinStock:boolean=false,showSlowMovingStock:boolean = false ): Observable<any> {
    return this.http.get(`${connectingString}Product/Products?isFuture=${isFuture}&showActive=${showActiveProduct}&showMinStock=${showMinStock}&showSlowMovingStock=${showSlowMovingStock}`);
  }
  getStocksByProductId(id:number,isFutureStock:boolean): Observable<any> {
    return this.http.get(`${connectingString}Stock/AllStocksByProduct?id=${id}&isFutureStock=${isFutureStock}`);
  }
  updateAlertCount(name:string, alertCount:number): Observable<any> {
    return this.http.patch(`${connectingString}Product/UpdateStockAlert?name=${name}&alertCount=${alertCount}`,{});
  }
  updateStatus(name:string, status:boolean): Observable<any> {
    return this.http.patch(`${connectingString}Product/UpdateStatus?name=${name}&status=${status}`,{});
  }
  updateFutureStatus(stockId:number, status:boolean): Observable<any> {
    return this.http.patch(`${connectingString}Stock/UpdateFutureStock?stockId=${stockId}&status=${status}`,{});
  }
  getLevelnRack(): Observable<any> {
    return this.http.get(`${connectingString}Stock/GetLevelNRack`);
  }
}
