<div class="card">
    <div class="card-header">
        <h1 class="h3 mb-2 text-gray-800">Stock</h1>
    </div>
    <div class="card-body">
        <div class="input-group mb-3">
            <input type="text" placeholder="Search..." class="form-control col-4" 
            (keyup)="handleDataChange()"  [(ngModel)]="searchText"
            >
            &nbsp;
            <button [hidden]="!isShowSpecialAllow()" class="btn btn-primary" (click)="getMinStocks()"  type="button" data-toggle="modal" data-target="#minStockModal" >Show Minimum Stock</button>
            &nbsp;

            <button [hidden]="!isShowSpecialAllow()" class="btn btn-success" (click)="getSlowMovingStocks()" type="button"  data-toggle="modal" data-target="#slowMovingModal"  >Show Slow Moving Stock</button>
            &nbsp;
            <div>
                <button  class="btn btn-primary" (click)="getFutureStocks()" type="button"  data-toggle="modal" data-target="#futureStockModal"  >Show Future Stock</button>
            </div>
          </div>
        <ag-grid-angular  (cellValueChanged)="onCellValueChanged($event)"  class="ag-theme-quartz" style="height: 500px;" [rowData]="rowFilteredData" [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef" [gridOptions]="gridOptions">
        </ag-grid-angular>

    </div>
</div>

<div class="modal fade" id="auditModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
     
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Stock Informations</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="input-group mb-3">
                    <input type="text"  (keyup)="handleDataDetailChange()"  placeholder="Search..." class="form-control col-4" [(ngModel)]="searchDetailText">
                  </div>
                  
                <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="rowDetailFilteredData"
                    [columnDefs]="detailColumnDefs" [defaultColDef]="defaultColDef"  [gridOptions]="gridOptionsDetail">
                </ag-grid-angular>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="minStockModal" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
     
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Minimum Stock</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="input-group mb-3">
                    <input type="text"  (keyup)="handleMinDataDetailChange()"  placeholder="Search..." class="form-control col-4" [(ngModel)]="searchMinText">
                  </div>
                  
                <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="minRowFilteredData"
                    [columnDefs]="min_slowColumnDefs" [defaultColDef]="defaultColDef" [gridOptions]="gridOptionsMinimumStock">
                </ag-grid-angular>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="slowMovingModal" tabindex="-1" role="dialog" 
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
     
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Slow Moving Stock</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="input-group mb-3">
                    <input type="text"  (keyup)="handleSlowDataDetailChange()"  placeholder="Search..." class="form-control col-4" [(ngModel)]="searchSlowText">
                  </div>
                  
                <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="slowRowFilteredData"
                    [columnDefs]="min_slowColumnDefs" [defaultColDef]="defaultColDef" [gridOptions]="gridOptionsShowMoving">
                </ag-grid-angular>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="activateModal" tabindex="-1" role="dialog" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">

        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Confirmation</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to Active/Deactive Product?</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
                <button class="btn btn-primary" type="button" data-dismiss="modal"
                    (click)="onActivate()">Update</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="futureStockModal" tabindex="-1" role="dialog" 
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
     
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Transit (Future) Stock</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="input-group mb-3">
                    <input type="text"  (keyup)="handleFutureChange()"  placeholder="Search..." class="form-control col-4" [(ngModel)]="searchFutureText">
                  </div>
                  
                <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="rowFutureFilteredData"
                    [columnDefs]="futureColumnDefs" [defaultColDef]="defaultColDef" [gridOptions]="futureGridOptions">
                </ag-grid-angular>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<notifier-container></notifier-container>