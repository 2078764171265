<div class="card">
    <div class="card-header">
        <h1 class="h3 mb-2 text-gray-800">Level N Rack</h1>
    </div>
    <div class="card-body">
        <div class="input-group mb-3">
            <input type="text" (keyup)="handleDataDetailChange()" placeholder="Search..." class="form-control col-4"
                [(ngModel)]="searchDetailText">
        </div>

        <ag-grid-angular class="ag-theme-quartz" style="height: 500px;" [rowData]="rowDetailFilteredData"
            [columnDefs]="detailColumnDefs" [defaultColDef]="gridOptionsDetail" [gridOptions]="gridOptionsDetail">
        </ag-grid-angular>
    </div>