<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <h5 class="card-header">Total Product</h5>
                <div class="card-body">
                  <h5 class="card-title">{{rowData?.length ?? 0}}</h5>
                </div>
              </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <h5 class="card-header">Total Transit Product</h5>
                <div class="card-body">
                  <h5 class="card-title">{{rowFutureData?.length ?? 0}}</h5>
                </div>
              </div>
        </div>
       
    </div>
</div>
<div class="container mt-4">
    <!-- Card Container (Bootstrap Grid) -->
   
    <div class="row">
      <div class="col-12 col-md-6"> <!-- Make this column take up half the width on medium screens (col-md-6) -->
        <div class="card">
          <div class="card-header">
            Stock
          </div>
          <div class="card-body">
            <div #chartStock style="width: 100%; height: 400px;"></div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6"> <!-- Make this column take up half the width on medium screens (col-md-6) -->
        <div class="card">
          <div class="card-header">
            Transit Stock
          </div>
          <div class="card-body">
            <div #chartFutureStock style="width: 100%; height: 400px;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-4">
    <!-- Card Container (Bootstrap Grid) -->
    <div class="row">
        <div class="col-12 col-md-6"> <!-- Make this column take up half the width on medium screens (col-md-6) -->
            <div class="card">
              <div class="card-header">
                Stock
              </div>
              <div class="card-body">
                <ag-grid-angular  class="ag-theme-quartz" style="height: 500px;" [rowData]="rowData" [columnDefs]="columnDefs"
                [defaultColDef]="columnDefs" [gridOptions]="gridOptions">
            </ag-grid-angular>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6"> <!-- Make this column take up half the width on medium screens (col-md-6) -->
            <div class="card">
              <div class="card-header">
                Transit Stock
              </div>
              <div class="card-body">
                <ag-grid-angular  class="ag-theme-quartz" style="height: 500px;" [rowData]="rowFutureData" [columnDefs]="columnDefs"
                [defaultColDef]="columnDefs" [gridOptions]="gridFutureOptions">
            </ag-grid-angular>
              </div>
            </div>
          </div>
    </div>
  </div>
  

